import React from 'react';

import Layout from '../../../blocks/Layout';
import SEO from '../../../blocks/SEO';
import Header from './Header';
import Features from './Features';
import Testimonials from './Testimonials';

const LandingPage = props => {
  const {
    pathContext: {
      landingPage: {
        name, 
        ctaUrl, 
        title, 
        description, 
        wistiaHash, 
        subTitle, 
        features, 
        testimonials,
        activeCampaignFormId
      }
    } 
  } = props;

  const headerProps = { title, description, wistiaHash }
  const featuresProps = { subTitle, features, activeCampaignFormId }
  const testimonialsProps = { testimonials }

  return (
    <Layout ctaUrl={ctaUrl}>
      <SEO title={`${name} | Deadline Funnel`} />
      <Header {...headerProps} />
      <Features {...featuresProps} />
      <Testimonials {...testimonialsProps} />
    </Layout>
  )
};

export default LandingPage;