import React from 'react';
import PropTypes from 'prop-types';

import styles from './Header.module.css';

import Title from '../../../../elements/Title';
import Container from '../../../../blocks/Container';
import FullWidthRow from '../../../../blocks/FullWidthRow';
import Image from '../../../../elements/Image';
import WistiaEmbed from '../../../../elements/WistiaEmbed';
import Text from '../../../../elements/Text';

const Header = (props) => {
  const { title, description, wistiaHash } = props;

  return (
    <div className={styles.header}>
      <Container>
        <Container type="mobile">
          <FullWidthRow className="mb-4">
            <Title type="white">
              <br />
              {title}
            </Title>
            <Text className={`${styles.header__subTitle} text-center`}>
              {description}
            </Text>
          </FullWidthRow>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-12 order-xl-1 order-lg-0 order-0 d-flex flex-column align-items-md-center h-100">
              <div className={`${styles.header__video}`}>
                <WistiaEmbed type="inline" hashedId={wistiaHash} />
              </div>
              <Image name="guy" className={`${styles.header__guy}`} />
              <Image name="girl" className={`${styles.header__girl}`} />
            </div>
          </div>
        </Container>
      </Container>
      <div className={styles.header__clouds} />
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  wistiaHash: PropTypes.string.isRequired,
};

export default Header;
